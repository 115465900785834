@import url("https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap");

:root {
  --font-base: "DM Sans", sans-serif;

  --primary-color: #800000; /* Updated to match new theme color */
  --secondary-color: #ffd700; /* Updated to match new theme color */
  --black-color: #333333;
  --lightGray-color: #e4e4e4;
  --gray-color: #666666;
  --white-color: #ffffff;
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  scroll-behavior: smooth;
}

body {
  font-family: var(--font-base);
  background-color: var(--primary-color);
  color: var(--black-color);
}

.app__about {
  flex: 1;
  width: 100%;
  flex-direction: column;
}

.head-text {
  font-size: 2.75rem;
  font-weight: 800;
  text-align: center;
  color: var(--black-color);
  text-transform: capitalize;
  margin-bottom: 2rem;
  transition: color 0.3s ease;

  span {
    color: var(--secondary-color);
  }

  @media screen and (max-width: 450px) {
    font-size: 2rem;
    margin-bottom: 1.5rem;
  }
}

.app__profiles {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-wrap: wrap;
  margin-top: 2rem;
}

.app__profile-item {
  width: 190px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  margin: 2rem;
  background-color: var(--white-color);
  border-radius: 10px;
  padding: 1rem;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;

  img {
    width: 100%;
    height: 170px;
    border-radius: 15px;
    object-fit: cover;
  }

  .bold-text {
    font-size: 1.25rem;
    color: var(--black-color);
    text-align: center;
  }

  .p-text {
    font-size: 1rem;
    color: var(--gray-color);
    text-align: center;
    margin-top: 1rem;
  }

  &:hover {
    transform: translateY(-10px);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
  }

  @media screen and (min-width: 2000px) {
    width: 370px;
    margin: 2rem 4rem;

    img {
      height: 320px;
    }
  }

  @media screen and (max-width: 450px) {
    width: 100%;
    padding: 1rem;

    .bold-text {
      font-size: 1rem;
    }

    .p-text {
      font-size: 0.875rem;
    }
  }
}

.footer {
  padding: 40px 20px;
  background: linear-gradient(135deg, #ff7e5f, #feb47b);
  color: #fff;
  text-align: center;

  .head-text {
    font-size: 2.5rem;
    margin-bottom: 20px;
  }

  .app__footer-cards {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
    margin-bottom: 40px;
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;

    .app__footer-card {
      background: rgba(0, 0, 0, 0.1);
      padding: 20px;
      border-radius: 10px;
      display: flex;
      flex-direction: row;
      align-items: center;
      text-align: center;
      width: 300px;
      margin: 10px;

      img {
        width: 40px;
        margin-right: 10px;
      }

      .p-text {
        color: #333;
        text-decoration: none;
        font-size: 1rem;
      }

      .p-text:hover {
        text-decoration: underline;
      }
    }
  }

  .app__footer-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 60%;
    margin: 0 auto;

    .app__flex {
      margin-bottom: 20px;
      width: 100%;
      max-width: 500px;

      input,
      textarea {
        width: 100%;
        padding: 15px;
        border: 2px solid #fff; /* Border for better visibility */
        border-radius: 15px; /* Rounded edges */
        margin-bottom: 10px;
        font-size: 1rem;
        background: transparent; /* Transparent background */
        color: #fff; /* White text color */
      }

      textarea {
        height: 150px; /* Adjusted height for better appearance */
        resize: none; /* Prevents the textarea from being resized */
      }

      button {
        padding: 15px 30px; /* Enlarged button size */
        background: #ffeb3b; /* Bright background color for the button */
        border: none;
        border-radius: 30px; /* Rounded edges for the button */
        color: #333; /* Dark text color for contrast */
        font-size: 1.2rem; /* Larger font size */
        cursor: pointer;
        transition: background 0.3s ease;

        &:hover {
          background: #ffd700;
        }
      }
    }
  }

  .app__footer-projects {
    margin-top: 40px;

    h3 {
      font-size: 1.5rem;
      margin-bottom: 20px;
    }

    ul {
      list-style-type: none;
      padding: 0;

      li {
        margin-bottom: 10px;

        a {
          color: #fff;
          text-decoration: none;
        }

        a:hover {
          text-decoration: underline;
        }
      }
    }
  }

  @media screen and (max-width: 768px) {
    .app__footer-cards {
      flex-direction: column;
      align-items: center;
    }

    .app__footer-form {
      width: 100%;
      margin: 1rem 0;

      .app__flex {
        max-width: 100%;
      }
    }
  }
}
